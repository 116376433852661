import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout/layout"
import Seo from "../components/seo"
import Hero from "../components/Index/hero"
import BestServices from "../components/Index/bestServices"
import HowItWorks from "../components/Index/howItWorks"
import PopularServices from "../components/Index/popularServices"
import WhyHire from "../components/Index/whyHire"
import CompanyReviewSection from "../components/Index/companyReviewSection"
import WhatHelp from "../components/Index/whatHelp"
import Faq from "../components/Index/faq"
import Description from "../components/Index/description"
import Footer from "../components/Layout/footer"
import Navbar from "../components/Layout/navbar"
import { useEffect } from "react"
import { setRegionImage } from "../services/imageHelper"

const RegionTemplate = ({ data, pageContext }) => {
  const region = data.allRegion.nodes[0]

  useEffect(() => {
    setRegionImage(region, `${process.env.GATSBY_WRANGLER_URL}/${region.countryCode}/${region.code}.jpg`)
  })

  return (
    <Layout page="country">
      <Navbar locationName={region.name} />
      <Seo title={"Best " + region.name + " Home Services - Handyman, Lawn & More"} />
      <Hero locationName={region.name} />
      <BestServices regionCode={pageContext.regionCode} cities={data.allCity.nodes} region={region} />
      <HowItWorks />
      <PopularServices locationName={region.name} />
      <WhyHire />
      <CompanyReviewSection />
      <WhatHelp />
      <Faq />
      <Description />
      <Footer locationName={region.name} />
    </Layout>
  )
}

export default RegionTemplate

export const regionQuery = graphql`
  query ($regionCode: String!, $countryCode: String!) {
    allRegion(filter: { code: { eq: $regionCode }, countryCode: { eq: $countryCode } }) {
      nodes {
        id
        code
        name
        countryCode
      }
    }
    allCity(filter: { regionCode: { eq: $regionCode }, countryCode: { eq: $countryCode } }) {
      nodes {
        id
        name
      }
    }
  }
`
